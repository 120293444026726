<template>
    <div v-if="item">
        <v-chip x-small label :color="getMainStatusColor(item.documentationStatus)">{{ formatName(item.documentationStatus) }}</v-chip>
    </div>
</template>
<script>
export default {
    data: () => ({
        item: null
    }),
    mounted() {
    this.item = this.params.data;
  },
  methods: {
    formatName(status){
        switch(status){
            case 'OPEN':
                return 'ACTIVE';
            default:
                return status;
        }
    },
    getMainStatusColor(status) {
      switch (status) {
        case "OPEN":
          return "success";
        case "CANCELLED":
          return "warning";
        case "CLOSED":
          return "red";
      }
    },
  }
}
</script>